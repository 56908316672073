import React from "react"
import Layout from "~components/layout"
import SEO from "~components/seo"
import { useStaticQuery, graphql} from "gatsby"
import RichText from '~components/richText'
import BodyClassName from "react-body-classname"

import styled from "styled-components"
import theme from "~theme/theme"

import { MenuPageWrapper, MenuPageContent, Person, PersonImage } from "../theme/common"

import MenuPageSidebar from '~components/MenuPageSidebar'

const Contributors = ({ data }) => {
  const contributors = data.allSanityPerson.nodes
  if(!contributors) return null;

  return (
    <Layout>
      <SEO title="Contributors" />
      <BodyClassName className="info-page">
        <MenuPageWrapper>
          <MenuPageSidebar />
          <MenuPageContent>
            {contributors.map((member, index) => {
              return(
                <Person key={index}>
                  <div>
                    <PersonImage image={member.image?.asset?.url} />
                    <div>
                      <h2 className="medium" style={{marginBottom: '0.2em'}}>
                        {member.title}
                      </h2>
                      {member.role &&
                        <p style={{marginBottom: '0'}}>{member.role}</p>
                      }
                    </div>
                  </div>
                  <div>
                    <RichText content={member._rawShortBio} />
                  </div>
                </Person>
              )
            }
            )}
          </MenuPageContent>
        </MenuPageWrapper>
      </BodyClassName>
    </Layout>
  )
}

export const query = graphql`
{
  allSanityPerson(filter: {group: {eq: "contributor"}}, sort: {order: ASC, fields: title}) {
    nodes {
      title
      _rawShortBio
      role
      image {
        asset {
          url
        }
      }
    }
  }
}
`

export default Contributors
